<template lang="html">
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 191.05" style="display:block">
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<rect class="cls-1 ai-box" x="318.24" width="158.03" height="149.67" :style="{'fill':logoColor==='black'?'#edd46f':'transparent'}" />
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-first-i" d="M1.86,32.6A1.86,1.86,0,0,1,0,30.74V6.51A1.86,1.86,0,0,1,1.86,4.64H29.62a1.86,1.86,0,0,1,1.86,1.87V30.74a1.86,1.86,0,0,1-1.86,1.86Zm0,117A1.86,1.86,0,0,1,0,147.75L.62,96.39,0,44.82A1.85,1.85,0,0,1,1.86,43h28a1.85,1.85,0,0,1,1.87,1.86l-.62,51.57.62,51.36a1.86,1.86,0,0,1-1.87,1.87Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-m" d="M73.31,111.3l.63,36.45a1.86,1.86,0,0,1-1.87,1.87H43.7a1.86,1.86,0,0,1-1.87-1.87l.63-36.45-.21-66.48A1.85,1.85,0,0,1,44.11,43H70.62a1.85,1.85,0,0,1,1.87,1.86l-.63,11.6c7.46-10.36,17-16,30.66-16,14.91,0,25.47,6.42,30.44,20.09,9.12-16.15,19.89-20.09,32.52-20.09,21.54,0,34,13.05,34,41.42V111.3l.41,36.45a1.86,1.86,0,0,1-1.87,1.87H170a1.86,1.86,0,0,1-1.87-1.87l.62-36.45v-29c0-12.84-5.17-19.06-13.67-19.06s-18.84,11.4-18.84,33.14V111.3l.62,36.45a1.86,1.86,0,0,1-1.87,1.87h-28a1.86,1.86,0,0,1-1.86-1.87l.62-36.45v-29c0-12.84-5-19.06-13.67-19.06S73.31,74.65,73.31,96.39Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-a" d="M285.18,149.62a1.86,1.86,0,0,1-1.87-1.87l.42-10.56c-7.46,9.11-18,14.5-31.48,14.5-30.24,0-46.19-24-46.19-55.09,0-30.86,17.81-55.71,46.81-55.71,13.25,0,23.61,4.76,30.86,13.46l-.62-9.53A1.85,1.85,0,0,1,285,43h27.75a1.85,1.85,0,0,1,1.87,1.86L314,96.39l.62,51.36a1.86,1.86,0,0,1-1.87,1.87ZM261,131c15.12,0,23.19-11.39,23.4-34,.21-22.78-7.87-35.21-22.78-35.41-15.74-.42-23.82,11.39-23.82,33.34C237.75,119,246,131.19,261,131Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-last-i" d="M442.36,32.6a1.86,1.86,0,0,1-1.86-1.86V6.51a1.86,1.86,0,0,1,1.86-1.87h27.76A1.86,1.86,0,0,1,472,6.51V30.74a1.86,1.86,0,0,1-1.86,1.86Zm0,117a1.86,1.86,0,0,1-1.86-1.87l.62-51.36-.62-51.57A1.85,1.85,0,0,1,442.36,43h28a1.85,1.85,0,0,1,1.87,1.86l-.62,51.57.62,51.36a1.86,1.86,0,0,1-1.87,1.87Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-n" d="M484.4,149.62a1.86,1.86,0,0,1-1.86-1.87l.62-49.29L483,44.82A1.85,1.85,0,0,1,484.82,43h26.3A1.85,1.85,0,0,1,513,44.82l-.63,11.6c7.25-9.32,19.47-16,34.38-16,22.58,0,37.08,15.33,37.08,41.42v22.37l.62,43.49a1.86,1.86,0,0,1-1.87,1.87H554.82a1.86,1.86,0,0,1-1.86-1.87l.41-43.49v-22c0-11.6-6.83-19.06-16.15-19.06C526,63.25,514,73.61,514,96.39v7.87l.42,43.49a1.86,1.86,0,0,1-1.87,1.87Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-e" d="M695,113.58a1.6,1.6,0,0,1,1.66,1.87c-2.7,19.46-21.34,36.86-51.16,36.86-35.62,0-54.88-23.4-54.88-55.71,0-34,20.29-56.34,54.26-56.34,35,0,54.47,23.2,55.09,60.48a1.86,1.86,0,0,1-1.86,1.87H621.92c1.24,20.08,9.11,29,24,29,10.36,0,17.82-4.76,20.72-16.15a2.42,2.42,0,0,1,2.27-1.87ZM645.12,60.77c-12.43,0-19.89,7.45-22.37,22.16h43.7C665.21,71.54,658.78,60.77,645.12,60.77Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-g-bottom" d="M400.61,149.56c-1.15,14.48-10.1,21-22.65,21s-19.68-5.18-21.13-13.88a2.25,2.25,0,0,0-2.28-1.86H327.84a1.86,1.86,0,0,0-1.87,1.86c1.66,23.4,23,34.79,50.95,34.38,35.21,0,52.81-17.4,52.81-44.32l-.08-20.13Z"/>
				<path :style="{'fill':logoColor==='black'?'':'#fff'}" class="letter letter-g-top" d="M370.09,149c13.25,0,23.61-5.59,30.65-14.91v8.52l28.88-22.46-.09-22.31.41-53A1.85,1.85,0,0,0,428.08,43H400.74a1.84,1.84,0,0,0-1.86,1.86l.62,9.74c-7.25-8.5-17.61-13.05-30.86-13.05-29,0-46.81,24.23-46.81,54.05C321.83,125.59,338.4,149,370.09,149Zm7.25-87.2c14.91.21,23,12.43,22.78,34.59-.21,21.54-8.29,32.31-23.41,32.31-14.91.21-23.19-11.39-23.19-34.38C353.52,73,361.6,61.39,377.34,61.8Z"/>
			</g>
		</g>
	</svg>

</template>

<script>
export default {
	name: 'Logo',
	props: ["color"],
	data() {
		return {
			logoColor: this.color
		}
	},
	watch: {
		'color': function(val) {
			this.logoColor = val
		}
	}
}
</script>

<style lang="css" scoped>
</style>
