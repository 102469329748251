<template lang="html">
	<v-main id="main-background">
		<v-container>
			<v-layout column style="padding:20px 0;text-align:center" v-if="gettingStartedRequestSuccess" align-center>
				<v-flex>
					<v-layout column align-center style="position:absolute;top:40%;left:50%;transform:translate(-50%, -50%)">
						<v-flex style="font-family:JostMedium;" :style="{'font-size':$vuetify.breakpoint.smAndUp?'40px':'30px'}">
							Thank you for submitting the details!
						</v-flex>
						<v-flex style="margin-top:30px" :style="{'font-size':$vuetify.breakpoint.smAndUp?'20px':'16px'}">
							Redirecting to homepage in {{this.redirectTimer}}
						</v-flex>
						<v-flex style="margin-top:60px">
							<div style="width:200px">
								<Logo color="black" />
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<v-layout column style="padding:20px 0;" align-center v-else>
				<v-flex style="width:100%;margin-bottom:20px">
					<v-layout row wrap align-center justify-center>
						<v-flex xs12 sm12 md5 style="font-family:JostBold;font-size:24px;display:flex;align-items:center;" :style="{'flex-direction':$vuetify.breakpoint.xsOnly?'column':'row'}">
							Get started on your
							<div style="width:120px;margin:0 10px">
								<Logo color="black" />
							</div>
							idea
						</v-flex>
						<v-flex md4>
							<v-layout column class="user-details">
								<v-flex>
									<v-text-field v-model="name" label="Full name" :rules="nameRules" solo flat color="black"></v-text-field>
								</v-flex>
								<v-flex>
									<v-text-field v-model="company" label="Company" :rules="companyRules" solo flat color="black"></v-text-field>
								</v-flex>
								<v-flex>
									<v-text-field v-model="email" label="Email address" :rules="emailRules.concat(emailRequiredRule)" flat solo color="black"></v-text-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex style="width:100%" v-if="$vuetify.breakpoint.xsOnly">
					<table>
						<tr>
							<th>Value Propositions</th>
						</tr>
						<tr style="height:200px;">
							<td>
								<textarea v-model="valuePropositions" placeholder="What value do we deliver to the customer?
Which one of our customer’s problems are we helping to solve?
What bundles of products and services are we offering to each Customer Segment?
Which customer needs are we satisfying?

CHARACTERISTICS
Newness
Performance
Customization
'Getting the Job Done'
Design
Brand/Status
Price
Cost Reduction
Risk Reduction
Accessibility
Convenience/Usability"></textarea>
							</td>
						</tr>
						<tr>
							<th>Customer Segments</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="customerSegments" placeholder="For whom are we creating value?
Who are our most important customers?

Mass Market
Niche Market
Segmented
Diversified
Multi-sided Platform"></textarea>
							</td>
						</tr>
						<tr>
							<th>Channels</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="channels" placeholder="Through which Channels do our Customer Segments want to be reached?
How are we reaching them now?
How are our Channels integrated?
Which ones work best?
Which ones are most cost-efficient?
How are we integrating them with customer routines?

CHANNEL PHASES
1. Awareness
2. Evaluation
3. Purchase
4. Delivery
5. After sales"></textarea>
							</td>
						</tr>
						<tr>
							<th>Customer Relationships</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="customerRelationships" placeholder="What type of relationship does each of our Customer Segments expect us to establish and maintain with them?
Which ones have we established?
How are they integrated with the rest of our business model?
How costly are they?

EXAMPLES
Personal assistance
Dedicated Personal Assistance
Self-Service
Automated Services
Communities
Co-creation"></textarea>
							</td>
						</tr>
						<tr>
							<th>Revenue Streams</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="revenueStreams" placeholder="For what value are our customers really willing to pay?
For what do they currently pay?
How are they currently paying?
How would they prefer to pay?
How much does each Revenue Stream contribute to overall revenues?

TYPES: Asset sale, Usage fee, Subscription Fees, Lending/Renting/Leasing, Licensing, Brokerage fees, Advertising
FIXED PRICING: List Price, Product feature dependent, Customer segment dependent, Volume dependent
DYNAMIC PRICING: Negotiation (bargaining), Yield Management, Real-time-Market"></textarea>
							</td>
						</tr>
						<tr>
							<th>Key Activities</th>
						</tr>
						<tr style="height:200px;">
							<td>
								<textarea v-model="keyActivities" placeholder="What Key Activities do our Value Propositions require?
Our Distribution Channels?
Customer Relationships?
Revenue streams?

CATEGORIES
Production
Problem Solving
Platform/Network"></textarea>
							</td>
						</tr>
						<tr>
							<th>Key Resources</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="keyResources" placeholder="What Key Resources do our Value Propositions require?
Our Distribution Channels?
Customer Relationships?
Revenue Streams?

TYPES OF RESOURCES
Physical
Intellectual (brand patents, copyrights, data)
Human
Financial"></textarea>
							</td>
						</tr>
						<tr>
							<th>Key Partners</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="keyPartners" placeholder="Who are our Key Partners?
Who are our key suppliers?
Which Key Resources are we acquiring from partners?
Which Key Activities do partners perform?

MOTIVATIONS FOR PARTNERSHIPS
Optimization and economy
Reduction of risk and uncertainty
Acquisition of particular resources and activities"></textarea>
							</td>
						</tr>
						<tr>
							<th>Competitor Landscape</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="competitorLandscape" placeholder="Who are our Key Competitors?
What are our competitors offering and which segments do they target?

TYPES OF COMPETITORS
Similar Value Proposition
A part of the same value chain
Adjacent services/products
Similar target customer segments"></textarea>
							</td>
						</tr>
						<tr>
							<th>Cost Structure</th>
						</tr>
						<tr style="height:200px">
							<td>
								<textarea v-model="costStructure" placeholder="What are the most important costs inherent in our business model?
Which Key Resources are most expensive?
Which Key Activities are most expensive?

IS YOUR BUSINESS MORE
Cost Driven (leanest cost structure, low price value proposition, maximum automation, extensive outsourcing)
Value Driven (focused on value creation, premium value proposition)

SAMPLE CHARACTERISTICS
Fixed Costs (salaries, rents, utilities), Variable costs, Economies of scale, Economies of scope"></textarea>
							</td>
						</tr>
					</table>
				</v-flex>
				<v-flex style="width:100%" v-else>
					<table>
						<tr>
							<th>Key Partners</th>
							<th>Key Activities</th>
							<th>Value Propositions</th>
							<th>Customer Relationships</th>
							<th>Customer Segments</th>
						</tr>
						<tr style="height:200px;">
							<td rowspan="3">
								<textarea v-model="keyPartners" placeholder="Who are our Key Partners?
Who are our key suppliers?
Which Key Resources are we acquiring from partners?
Which Key Activities do partners perform?

MOTIVATIONS FOR PARTNERSHIPS
Optimization and economy
Reduction of risk and uncertainty
Acquisition of particular resources and activities"></textarea>
							</td>
							<td>
								<textarea v-model="keyActivities" placeholder="What Key Activities do our Value Propositions require?
Our Distribution Channels?
Customer Relationships?
Revenue streams?

CATEGORIES
Production
Problem Solving
Platform/Network"></textarea>
							</td>
							<td rowspan="3">
								<textarea v-model="valuePropositions" placeholder="What value do we deliver to the customer?
Which one of our customer’s problems are we helping to solve?
What bundles of products and services are we offering to each Customer Segment?
Which customer needs are we satisfying?

CHARACTERISTICS
Newness
Performance
Customization
'Getting the Job Done'
Design
Brand/Status
Price
Cost Reduction
Risk Reduction
Accessibility
Convenience/Usability"></textarea>
							</td>
							<td>
								<textarea v-model="customerRelationships" placeholder="What type of relationship does each of our Customer Segments expect us to establish and maintain with them?
Which ones have we established?
How are they integrated with the rest of our business model?
How costly are they?

EXAMPLES
Personal assistance
Dedicated Personal Assistance
Self-Service
Automated Services
Communities
Co-creation"></textarea>
							</td>
							<td>
								<textarea v-model="customerSegments" placeholder="For whom are we creating value?
Who are our most important customers?

Mass Market
Niche Market
Segmented
Diversified
Multi-sided Platform"></textarea>
							</td>
						</tr>
						<tr>
							<th>Key Resources</th>
							<th>Channels</th>
							<th>Competitor Landscape</th>
						</tr>
						<tr style="height:200px;">
							<td>
								<textarea v-model="keyResources" placeholder="What Key Resources do our Value Propositions require?
Our Distribution Channels?
Customer Relationships?
Revenue Streams?

TYPES OF RESOURCES
Physical
Intellectual (brand patents, copyrights, data)
Human
Financial"></textarea>
							</td>
							<td>
								<textarea v-model="channels" placeholder="Through which Channels do our Customer Segments want to be reached?
How are we reaching them now?
How are our Channels integrated?
Which ones work best?
Which ones are most cost-efficient?
How are we integrating them with customer routines?

CHANNEL PHASES
1. Awareness
2. Evaluation
3. Purchase
4. Delivery
5. After sales"></textarea>
							</td>
							<td>
								<textarea v-model="competitorLandscape" placeholder="Who are our Key Competitors?
What are our competitors offering and which segments do they target?

TYPES OF COMPETITORS
Similar Value Proposition
A part of the same value chain
Adjacent services/products
Similar target customer segments"></textarea>
							</td>
						</tr>
					</table>
					<table style="border-top:none">
						<tr>
							<th style="border-top:none">Cost Structure</th>
							<th style="border-top:none">Revenue Streams</th>
						</tr>
						<tr style="height:200px;">
							<td>
								<textarea v-model="costStructure" placeholder="What are the most important costs inherent in our business model?
Which Key Resources are most expensive?
Which Key Activities are most expensive?

IS YOUR BUSINESS MORE
Cost Driven (leanest cost structure, low price value proposition, maximum automation, extensive outsourcing)
Value Driven (focused on value creation, premium value proposition)

SAMPLE CHARACTERISTICS
Fixed Costs (salaries, rents, utilities), Variable costs, Economies of scale, Economies of scope"></textarea>
							</td>
							<td>
								<textarea v-model="revenueStreams" placeholder="For what value are our customers really willing to pay?
For what do they currently pay?
How are they currently paying?
How would they prefer to pay?
How much does each Revenue Stream contribute to overall revenues?

TYPES: Asset sale, Usage fee, Subscription Fees, Lending/Renting/Leasing, Licensing, Brokerage fees, Advertising
FIXED PRICING: List Price, Product feature dependent, Customer segment dependent, Volume dependent
DYNAMIC PRICING: Negotiation (bargaining), Yield Management, Real-time-Market"></textarea>
							</td>
						</tr>
					</table>
				</v-flex>
				<v-flex style="margin:30px 0;width:100%">
					<v-layout row justify-center>
						<v-flex xs12 sm6 md3>
							<v-btn large :loading="loading" block class="accent--text black" rounded @click="submitForm">Submit</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import Logo from '../Outer/Logo'
export default {
	name: 'BMC',
	components: {
		Logo
	},
	data() {
		return {
			name: '',
			company: '',
			email: '',
			keyPartners: '',
			keyActivities: '',
			valuePropositions: '',
			customerRelationships: '',
			customerSegments: '',
			keyResources: '',
			channels: '',
			competitorLandscape: '',
			costStructure: '',
			revenueStreams: '',
			gettingStartedRequestSuccess: false,
			loading: false,
			redirectTimer: 5,
			redirectInterval: null,
			nameRules: [v => !!v || 'Name cannot be empty'],
			companyRules: [v => !!v || 'Company name cannot be empty'],
			emailRules: [
				v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) || 'Email address not valid'
			],
			emailRequiredRule: [v => !!v || 'Email cannot be empty'],
		}
	},
	beforeDestroy() {
		clearInterval(this.redirectInterval)
	},
	methods: {
		submitForm() {
			let that = this
			that.loading = true
			let formData = new FormData()
			if(that.name === '' || that.company === '' || that.email === '') {
				that.loading = false
				return
			}
			formData.append('name', that.name)
			formData.append('company', that.company)
			formData.append('email', that.email)
			formData.append('keyPartners', that.keyPartners)
			formData.append('keyActivities', that.keyActivities)
			formData.append('valuePropositions', that.valuePropositions)
			formData.append('customerRelationships', that.customerRelationships)
			formData.append('customerSegments', that.customerSegments)
			formData.append('keyResources', that.keyResources)
			formData.append('channels', that.channels)
			formData.append('competitorLandscape', that.competitorLandscape)
			formData.append('costStructure', that.costStructure)
			formData.append('revenueStreams', that.revenueStreams)
			that.$http.post(that.$store.getters.getBaseUrl + '/api/v1/get-started', formData)
			.then(res => {
				if(res.data.success) {
					that.gettingStartedRequestSuccess = true
					that.redirectInterval = setInterval(() => {
						that.redirectTimer--
						if(that.redirectTimer === 0) {
							that.$router.push('/')
						}
					}, 1000)
				}
				that.loading=false
			})
			.catch(err => {
				console.log(err)
				that.loading=false
			})
		}
	}
}
</script>

<style lang="css" scoped>
#main-background{
	background:#f5f5f5;
}
.user-details >>> .v-input__slot{
	background:transparent!important;
	border-bottom:1px solid #353839;
	border-radius:0!important;
	font-size:16px;
}
.user-details >>> label{
	font-size:16px;
	color:#999;
	font-weight: normal
}
table{
	width:100%;
	border-spacing: 0;
	overflow:hidden
}
table, th, td{
	border: 1px solid #CACFD2
}
table:nth-child(1) {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
table:nth-child(2) {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
th{
	padding: 3px;
	background: #353839;
	color: #edd46f;
	font-family: JostMedium;
	font-weight: normal;
	letter-spacing: 0.6px;
	text-align: center;
	font-size:14px;
}
td{
	position:relative;
}
textarea{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	padding:5px;
	resize:none;
	font-size:14px;
}
textarea:focus{
	outline:none
}
::-webkit-scrollbar {
	width: 4px;
}
table:nth-child(1) th, td{
	width:20%;
}
</style>
